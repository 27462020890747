import { types } from "./types";

export const actions = {
    getQuoteById: payload => ({
        type: types.GET_QUOTE_BY_ID,
        payload
    }),
    getQuoteByIdSuccess: payload => ({
        type: types.GET_QUOTE_BY_ID_SUCCESS,
        payload
    }),
    getQuoteByIdFailed: payload => ({
        type: types.GET_QUOTE_BY_ID_FAILED,
        payload
    }),

    setQuoteInfo: payload => ({
        type: types.SET_QUOTE_INFO,
        payload
    }),
    updateQuoteInfo: payload => ({
        type: types.UPDATE_QUOTE_INFO,
        payload
    }),

    saveQuote: payload => ({
        type: types.SAVE_QUOTE,
        payload
    }),
    saveQuoteSuccess: payload => ({
        type: types.SAVE_QUOTE_SUCCESS,
        payload
    }),
    saveQuoteFailed: payload => ({
        type: types.SAVE_QUOTE_FAILED,
        payload
    }),

    updateQuote: payload => ({
        type: types.UPDATE_QUOTE,
        payload
    }),
    updateQuoteSuccess: payload => ({
        type: types.UPDATE_QUOTE_SUCCESS,
        payload
    }),
    updateQuoteFailed: payload => ({
        type: types.UPDATE_QUOTE_FAILED,
        payload
    }),

    deleteQuote: payload => ({
        type: types.DELETE_QUOTE,
        payload
    }),
    deleteQuoteSuccess: payload => ({
        type: types.DELETE_QUOTE_SUCCESS,
        payload
    }),
    deleteQuoteFailed: payload => ({
        type: types.DELETE_QUOTE_FAILED,
        payload
    }),

    hideQuote: payload => ({
        type: types.HIDE_QUOTE,
        payload
    }),
    hideQuoteSuccess: payload => ({
        type: types.HIDE_QUOTE_SUCCESS,
        payload
    }),
    hideQuoteFailed: payload => ({
        type: types.HIDE_QUOTE_FAILED,
        payload
    }),

    getQuotes: payload => ({
        type: types.GET_QUOTES,
        payload
    }),
    getQuotesSuccess: payload => ({
        type: types.GET_QUOTES_SUCCESS,
        payload
    }),
    getQuotesFailed: payload => ({
        type: types.GET_QUOTES_FAILED,
        payload
    }),

    setQuoteConfirmFlag: payload => ({
        type: types.SET_QUOTE_CONFIRM_FLAG,
        payload
    }),
    setQuoteConfirmFlagSuccess: payload => ({
        type: types.SET_QUOTE_CONFIRM_FLAG_SUCCESS,
        payload
    }),
    setQuoteConfirmFlagFailed: payload => ({
        type: types.SET_QUOTE_CONFIRM_FLAG_FAILED,
        payload
    }),

    setQuotePublishFlag: payload => ({
        type: types.SET_QUOTE_PUBLISH_FLAG,
        payload
    }),
    setQuotePublishFlagSuccess: payload => ({
        type: types.SET_QUOTE_PUBLISH_FLAG_SUCCESS,
        payload
    }),
    setQuotePublishFlagFailed: payload => ({
        type: types.SET_QUOTE_PUBLISH_FLAG_FAILED,
        payload
    }),

    setQuoteUnderReviewFlag: payload => ({
        type: types.SET_QUOTE_UNDER_REVIEW_FLAG,
        payload
    }),
    setQuoteUnderReviewFlagSuccess: payload => ({
        type: types.SET_QUOTE_UNDER_REVIEW_FLAG_SUCCESS,
        payload
    }),

    getTeeTimeReminders: payload => ({
        type: types.GET_TEE_TIME_REMINDERS,
        payload
    }),
    getTeeTimeRemindersSuccess: payload => ({
        type: types.GET_TEE_TIME_REMINDERS_SUCCESS,
        payload
    }),
    getTeeTimeRemindersFailed: payload => ({
        type: types.GET_TEE_TIME_REMINDERS_FAILED,
        payload
    }),

    getDueQuotes: payload => ({
        type: types.GET_DUE_QUOTES,
        payload
    }),
    getDueQuotesSuccess: payload => ({
        type: types.GET_DUE_QUOTES_SUCCESS,
        payload
    }),
    getDueQuotesFailed: payload => ({
        type: types.GET_DUE_QUOTES_FAILED,
        payload
    }),

    getPaidQuotes: payload => ({
        type: types.GET_PAID_QUOTES,
        payload
    }),
    getPaidQuotesSuccess: payload => ({
        type: types.GET_PAID_QUOTES_SUCCESS,
        payload
    }),
    getPaidQuotesFailed: payload => ({
        type: types.GET_PAID_QUOTES_FAILED,
        payload
    }),

    addGolfVacationItem: payload => ({
        type: types.ADD_GOLF_VACATION_ITEM,
        payload
    }),
    addGolfVacationItemSuccess: payload => ({
        type: types.ADD_GOLF_VACATION_ITEM_SUCCESS,
        payload
    }),
    deleteGolfVacationItem: payload => ({
        type: types.DELETE_GOLF_VACATION_ITEM,
        payload
    }),
    deleteGolfVacationItemSuccess: payload => ({
        type: types.DELETE_GOLF_VACATION_ITEM_SUCCESS,
        payload
    }),

    cloneGolfVacationItem: payload => ({
        type: types.CLONE_GOLF_VACATION_ITEM,
        payload
    }),
    cloneGolfVacationItemRequest: payload => ({
        type: types.CLONE_GOLF_VACATION_ITEM_REQUEST,
        payload
    }),
    cloneGolfVacationItemSuccess: payload => ({
        type: types.CLONE_GOLF_VACATION_ITEM_SUCCESS,
        payload
    }),
    cloneGolfVacationItemFailed: payload => ({
        type: types.CLONE_GOLF_VACATION_ITEM_FAILED,
        payload
    }),

    setGolfVacationItems: payload => ({
        type: types.SET_GOLF_VACATION_ITEMS,
        payload
    }),
    updateGolfVacationItem: payload => ({
        type: types.UPDATE_GOLF_VACATION_ITEM,
        payload
    }),
    updateGolfVacationItemOccupancy: payload => ({
        type: types.UPDATE_GOLF_VACATION_ITEM_OCCUPANCY,
        payload
    }),
    replaceGolfVacationItem: payload => ({
        type: types.REPLACE_GOLF_VACATION_ITEM,
        payload
    }),
    refreshGolfVacationItemName: payload => ({
        type: types.REFRESH_GOLF_VACATION_ITEM_NAME,
        payload
    }),

    setSelectedRooms: payload => ({
        type: types.SET_SELECTED_ROOMS,
        payload
    }),
    updateSelectedRoom: payload => ({
        type: types.UPDATE_SELECTED_ROOM,
        payload
    }),
    replaceSelectedRoom: payload => ({
        type: types.REPLACE_SELECTED_ROOM,
        payload
    }),

    addSelectedRoom: payload => ({
        type: types.ADD_SELECTED_ROOM,
        payload
    }),
    addSelectedRoomSuccess: payload => ({
        type: types.ADD_SELECTED_ROOM_SUCCESS,
        payload
    }),
    saveSelectedRoom: payload => ({
        type: types.SAVE_SELECTED_ROOM,
        payload
    }),
    deleteSelectedRoom: payload => ({
        type: types.DELETE_SELECTED_ROOM,
        payload
    }),
    deleteSelectedRoomSuccess: payload => ({
        type: types.DELETE_SELECTED_ROOM_SUCCESS,
        payload
    }),

    setSelectedCourses: payload => ({
        type: types.SET_SELECTED_COURSES,
        payload
    }),
    replaceSelectedCourse: payload => ({
        type: types.REPLACE_SELECTED_COURSE,
        payload
    }),
    updateSelectedCourse: payload => ({
        type: types.UPDATE_SELECTED_COURSE,
        payload
    }),
    updateSelectedCourseTbd: payload => ({
        type: types.UPDATE_SELECTED_COURSE_TBD,
        payload
    }),
    addSelectedCourse: payload => ({
        type: types.ADD_SELECTED_COURSE,
        payload
    }),
    deleteSelectedCourse: payload => ({
        type: types.DELETE_SELECTED_COURSE,
        payload
    }),
    updateTwoRoundsCourse: payload => ({
        type: types.UPDATE_TWO_ROUNDS_COURSE,
        payload
    }),

    saveSelectedCourse: payload => ({
        type: types.SAVE_SELECTED_COURSE,
        payload
    }),

    downloadConfirmationPdf: payload => ({
        type: types.DOWNLOAD_CONFIRMATION_PDF,
        payload
    }),
    sendConfirmationPdf: payload => ({
        type: types.SEND_CONFIRMATION_PDF,
        payload
    }),

    sendVoucher: payload => ({
        type: types.SEND_VOUCHER,
        payload
    }),
    sendVoucherSuccess: payload => ({
        type: types.SEND_VOUCHER_SUCCESS,
        payload
    }),

    sendFollowupEmail: payload => ({
        type: types.SEND_FOLLOWUP_EMAIL,
        payload
    }),
    sendFollowupEmailSuccess: payload => ({
        type: types.SEND_FOLLOWUP_EMAIL_SUCCESS,
        payload
    }),
    setFollowupFlag: payload => ({
        type: types.SET_FOLLOWUP_FLAG,
        payload
    }),
    setFollowupFlagSuccess: payload => ({
        type: types.SET_FOLLOWUP_FLAG_SUCCESS,
        payload
    }),

    sendPaymentReminderEmail: payload => ({
        type: types.SEND_PAYMENT_REMINDER_EMAIL,
        payload
    }),
    sendPaymentReminderEmailSuccess: payload => ({
        type: types.SEND_PAYMENT_REMINDER_EMAIL_SUCCESS,
        payload
    }),

    sendAfterTripFollowupEmail: payload => ({
        type: types.SEND_AFTER_TRIP_FOLLOWUP_EMAIL,
        payload
    }),
    sendAfterTripFollowupEmailSuccess: payload => ({
        type: types.SEND_AFTER_TRIP_FOLLOWUP_EMAIL_SUCCESS,
        payload
    }),

    sendThankYouEmail: payload => ({
        type: types.SEND_THANK_YOU_EMAIL,
        payload
    }),
    sendThankYouEmailSuccess: payload => ({
        type: types.SEND_THANK_YOU_EMAIL_SUCCESS,
        payload
    }),

    sendPreTripFollowupEmail: payload => ({
        type: types.SEND_PRE_TRIP_FOLLOWUP_EMAIL,
        payload
    }),
    sendGenericEmail: payload => ({
        type: types.SEND_GENERIC_EMAIL,
        payload
    }),
    sendConfirmationEmail: payload => ({
        type: types.SEND_CONFIRMATION_EMAIL,
        payload
    }),
}