import { diffDays, formatDate, getRounds, roundLikePHP } from "../config/utils";

const termsAndConditionsTemplate = `
<h3>Terms &amp; Conditions</h3>
<p><strong>Cancellation Policy</strong></p>
<ul>
   <li>
      <p>Room reservations and golf cancelled within five (5) days prior to day of arrival are subject to being charged 100% of golf package price.</p>
   </li>
</ul>
<p><strong>Inclement Weather &amp; Unused Portions of Golf Package Policies</strong></p>
<ul>
   <li>
      <p>Golf courses will only close for severe, threatening, unsafe weather conditions, or if the golf course is not playable. This decision is at the sole discretion of Pro Shop staff, and gift cards or rain checks come from them. Unused portions of golf package are not subject to refund.</p>
   </li>
</ul>
<p><strong>Dress Code</strong></p>
<ul>
   <li>
      <p>All players must comply with club dress codes. Acceptable men&apos;s golf attire: Mocks or shirt with a collar required. Bermuda length shorts and/or slacks are permitted, however, denim, cut-off, torn or ripped materials will not be allowed. Acceptable ladies attire: Blouse with collar or sleeves; Bermuda length shorts. Denim, cut-off, torn or ripped material will not be allowed. No T-shirts, tank tops, halter tops or swimwear permitted.</p>
   </li>
</ul>
<p><strong>Billing Privileges</strong></p>
<ul>
   <li>
      <p>All incidental charges outside of room rates, room tax, and golf is the responsibility of the individual traveler. If a traveler does not have a credit card, a cash deposit will be required upon check in at the front desk.</p>
   </li>
</ul>
<p><strong>Personal Coolers</strong></p>
<ul>
   <li>
      <p>Personal coolers are not allowed on the golf courses.</p>
   </li>
</ul>`;

export const checkAndAppendTermsAndConditions = (originalText, status) => {
	if (parseInt(status) !== 2 || originalText.includes('Terms &amp;')) {
		return originalText
	}

	return originalText + '\n' + termsAndConditionsTemplate;
}

export const normalize = (item, vacationId, index) => {
	const golfVacationItem = {
		id: item.id > 0 ? item.id : 0,
		vacation_id: vacationId,
		first_name: item.first_name,
		last_name: item.last_name,
		golf_package_id: item.golf_package.id, // check to see if we need to update from golf_package object
		disable_auto_populate: 'off',
		create_voucher: 'on',
		title: item.title,
		resort_title: item.resort_title || "",
		check_in_location: item.check_in_location || "",
		reservation_name: item.reservation_name || "",
		date_of_arrival: formatDate(item.date_of_arrival),
		date_of_departure: formatDate(item.date_of_departure),
		nr_golfers: item.number_golfers.value,
		nr_non_golfers: item.number_non_golfers.value,
		nr_rounds: 0,
		unlimited_rounds: item.unlimited_rounds ? "on" : "off",
		nr_nights: diffDays(item.date_of_arrival, item.date_of_departure) || 0,
		price: roundLikePHP(parseFloat(item.price)) || 0,
		price_as_deposit: item.price_as_deposit ? 1 : 0,
		show_tpp: item.show_tpp ? 'on' : 'off',
		currency: item.currency.value,
		markup: 25, // should remain 25? 
		note: "",
		due_days: item.due_days,
		deposit_per: item.deposit_per.value,
		total_price_per: item.total_price_per.value,
		total_price: item.total_price,
		additional_price_description: '',
		additional_price_amount: '',
		additional_price_markup: '',
		occupancy: item.occupancy,
		other_occupancy: item.other_occupancy || '',
		content_box: item.content_box,
		content_box_lodging: item.content_box_lodging,
		additional_info: '',
		order_id: index,
		status: item.status,
		published: item.published,
		modified_by: localStorage.getItem('userId'),
		exclusions: 'no exc,',
		description: checkAndAppendTermsAndConditions(item.description || '', item.status) || '',
		confirmation_id: item.confirmation_id || '',
	}
	golfVacationItem.pdf_description = golfVacationItem.description;

	return golfVacationItem;
}

// vacationId = the quote id and the index is used for order id 
export const buildGolfVacationItemPayload = (item, vacationId, index, golfCourses) => {

	if (item.date_of_arrival_and_departure.length === 2) {
		item.date_of_arrival = item.date_of_arrival_and_departure[0];
		item.date_of_departure = item.date_of_arrival_and_departure[1];
	}
	let normalizedItem = normalize(item, vacationId, index);

	const additionalAmount = item.additional_amounts.reduce((acc, entry) => {
		acc.amount.push(entry.amount);
		acc.description.push(entry.description.trim());
		acc.markup.push(entry.markup);
		return acc;
	}, { amount: [], description: [], markup: [] });
	normalizedItem.additional_price_amount = additionalAmount.amount.join('##');
	normalizedItem.additional_price_description = additionalAmount.description.join('##');
	normalizedItem.additional_price_markup = additionalAmount.markup.join('##');

	//copy paste :) 
	let decimals = item.totals.total_rounded !== 0 ? item.totals.total_rounded.split(".")[1] : 0;
	let isMoreThan0Value = parseInt(decimals) > 0 ? 1 : 0;
	let finalTotalPrice = item.totals.total_rounded !== 0 ? `${parseInt(item.totals.total_rounded.split(".")[0]) + isMoreThan0Value}` : 0;
	normalizedItem.total_price = finalTotalPrice;
	if (item.price_as_deposit) {
		normalizedItem.price = normalizedItem.total_price;
	}

	if (item.occupancy === 'golf_only') {
		normalizedItem.nr_nights = 0;
	}

	normalizedItem.nr_rounds = getRounds(golfCourses);
	return normalizedItem;
}