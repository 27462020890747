import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import { push } from "redux-first-history";
import { ordersService } from '../services';
import {
    ordersActions,
    ordersTypes,
} from '../redux';
import { toastr } from 'react-redux-toastr';

const saveOrder = function* (action) {
    try {
        console.log(action.payload)
        const response = yield call(ordersService.saveOrder, action.payload);

        yield put(ordersActions.saveOrderSuccess(response.data));
        yield put(push(`/order/view/${response.data.id}`));
    } catch (e) {
        console.log(e);
        yield put(ordersActions.saveOrderFailed());
    }
};

const getOrderById = function* (action) {
    try {
        const response = yield call(ordersService.getOrderById, action.payload);
        yield put(ordersActions.getOrderByIdSuccess(response.data));
    } catch (e) {
        console.log(e);
        yield put(ordersActions.getOrderByIdFailed());
    }
};

const deleteOrder = function* (action) {
    try {
        yield call(ordersService.deleteOrder, action.payload);
        yield put(ordersActions.deleteOrderSuccess());
        toastr.success('', 'The order was deleted successfully');

        const ordersState = yield select((state) => state.orders);
        yield put(ordersActions.getOrders({ page: ordersState.page, perPage: ordersState.perPage }))
    } catch (e) {
        console.log(e);
        yield put(ordersActions.deleteOrderFailed());
        toastr.error('', 'Something went wrong');
    }
};

const getOrders = function* (action) {
    try {
        const response = yield call(ordersService.getOrders, action.payload);
        yield put(ordersActions.getOrdersSuccess(response.data));
    } catch (e) {
        console.log(e);
        yield put(ordersActions.getOrdersFailed());
    }
};

const getOrderProducts = function* (action) {
    yield put(ordersActions.getOrderProductsRequest());
    try {
        const response = yield call(ordersService.getOrderProducts, action.payload);
        yield put(ordersActions.getOrderProductsSuccess(response.data));
    } catch (e) {
        console.log(e);
        yield put(ordersActions.getOrderProductsSuccess());
    }
};

const getUsersByEmail = function* (action) {
    yield put(ordersActions.getUsersByEmailRequest());
    try {
        const response = yield call(ordersService.getUsersByEmail, action.payload);
        yield put(ordersActions.getUsersByEmailSuccess(response.data));
    } catch (e) {
        console.log(e);
        yield put(ordersActions.getUsersByEmailFailed());
    }
};

const getUsersByProduct = function* (action) {
    try {
        const response = yield call(ordersService.getUsersByProduct, action.payload);
        yield put(ordersActions.getUsersByProductSuccess(response.data));
    } catch (e) {
        console.log(e);
        yield put(ordersActions.getUsersByProductFailed());
    }
};

const splitOrder = function* (action) {
    try {
        const response = yield call(ordersService.splitOrder, action.payload);

        yield put(ordersActions.splitOrderSuccess(response.data));
        yield put(push(`/order/view/${response.data.id}`));

        toastr.success('', 'The order was split successfully');
    } catch (e) {
        console.log(e);
        yield put(ordersActions.splitOrderFailed());
        toastr.error('', 'Something went wrong');
    }
};

const moveOrder = function* (action) {
    try {
        const response = yield call(ordersService.moveOrder, action.payload);

        yield put(ordersActions.moveOrderSuccess(response.data));
        //yield put(push(`/order/view/${response.data.id}`));

        toastr.success('', 'The order was moved successfully');
    } catch (e) {
        console.log(e);
        yield put(ordersActions.moveOrderFailed());
        toastr.error('', 'Something went wrong');
    }
};

export default function* ordersSaga() {
    yield all([
        takeLatest(ordersTypes.SAVE_ORDER, saveOrder),
        takeLatest(ordersTypes.GET_ORDER_BY_ID, getOrderById),
        takeLatest(ordersTypes.DELETE_ORDER, deleteOrder),
        takeLatest(ordersTypes.GET_ORDERS, getOrders),
        takeLatest(ordersTypes.GET_ORDER_PRODUCTS, getOrderProducts),
        takeLatest(ordersTypes.GET_USERS_BY_EMAIL, getUsersByEmail),
        takeLatest(ordersTypes.GET_USERS_BY_PRODUCT, getUsersByProduct),
        takeLatest(ordersTypes.SPLIT_ORDER, splitOrder),
        takeLatest(ordersTypes.MOVE_ORDER, moveOrder),
    ]);
}