import React, { useState, useEffect, useCallback } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Tooltip, Grid, TextField, Button, FormControl, RadioGroup, Radio, FormControlLabel, MenuItem, InputLabel, Checkbox, Collapse, Switch } from '@mui/material';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { capitalizeText, formatDate, formatDateForRange, getNights, selectMenuProps } from '../../../../config/utils';
import CachedIcon from '@mui/icons-material/Cached';
import Select from "react-select";
import { currencyOptions, numberOfGolfersOptions } from '../../../../config';
import { AdditionalAmounts, Courses, Descriptions, Lodging, Totals } from './itemComponents';
import { quotesActions } from '../../../../redux';

const GolfVacationItemInfo = (props) => {

    const [selectedPackage, setSelectedPackage] = useState(null);
    const [lodgingsCollapsed, setLodgingsCollapsed] = useState(true);
    const [coursesCollapsed, setCoursesCollapsed] = useState(true);
    const { classes, item, updateItem, replaceItem, refreshName } = props;

    useEffect(() => {
        let existingPackage = props.resortPackages.find(p => p.value == item.golf_package.id);
        let payload = existingPackage ? existingPackage : null;
        setSelectedPackage(payload);
    }, [item.golf_package]);

    const updatePackage = useCallback((newPackage) => {
        let payload = { id: newPackage ? newPackage.value : null };
        updateItem({ target: 'golf_package', value: payload, id: item.id });
    }, [item.id]);

    const displayRangeValue = () => {
        const dateOfArrivalAndDeparture = item.date_of_arrival_and_departure;
        if (dateOfArrivalAndDeparture.length === 2) {
            return `${formatDateForRange(dateOfArrivalAndDeparture[0])}  →  ${formatDateForRange(dateOfArrivalAndDeparture[1])}`;
        } else {
            return 'Choose dates';
        }
    }

    const updateDateRange = (value) => {
        let newItem = {
            ...item,
            date_of_arrival_and_departure: [formatDate(value[0]), formatDate(value[1])],
            nights: getNights(value[0], value[1])
        };

        replaceItem({ id: item.id, item: newItem });
    }

    const handleCapitalizedValueChange = (id, value, target) => {
        let capitalizedValue = capitalizeText(value);
        updateItem({ target: target, value: capitalizedValue, id: id });
    }

    return (
        <div>
            <div className={classes.golfVacationItemSection}>General Information</div>
            <Grid container spacing={2}>
                <Grid item sm={3} md={2}>
                    <TextField
                        label='Title'
                        value={item.title}
                        onChange={(e) => handleCapitalizedValueChange(item.id, e.target.value, 'title')}
                        required
                        error={item.title === ''}
                        fullWidth
                    />
                </Grid>
                <Grid item sm={3} md={2}>
                    <TextField
                        label='First name'
                        value={item.first_name}
                        onChange={(e) => handleCapitalizedValueChange(item.id, e.target.value, 'first_name')}
                        required
                        error={item.first_name === ''}
                        fullWidth
                    />
                </Grid>
                <Grid item sm={3} md={2}>
                    <TextField
                        label='Last name'
                        value={item.last_name}
                        onChange={(e) => handleCapitalizedValueChange(item.id, e.target.value, 'last_name')}
                        required
                        error={item.last_name === ''}
                        fullWidth
                    />
                </Grid>
                <Grid item md={1}>
                    <Tooltip title="Get Name">
                        <Button className={classes.getNameButton} variant='contained' onClick={() => refreshName({ id: item.id })}>
                            <CachedIcon />
                        </Button>
                    </Tooltip>
                </Grid>
                <Grid item md={2}>
                    <span className={classes.dateRangeContainer}>
                        <span className={item.date_of_arrival_and_departure.length === 2 ? classes.dateRangeLabel : `${classes.dateRangeLabel} ${classes.dateRangeLabelError}`}>
                            Date of Arrival/Departure
                        </span>
                        <DateRangePicker
                            locale={'en-US'}
                            value={item.date_of_arrival_and_departure}
                            onChange={(value) => updateDateRange(value)}
                            className={item.date_of_arrival_and_departure.length === 2 ? classes.dateRangeInput : `${classes.dateRangeInput} ${classes.dateRangeInputError}`}
                            calendarIcon={displayRangeValue}
                            clearIcon={null}
                        />
                    </span>
                </Grid>
            </Grid>
            <div className={classes.golfVacationItemSection}>Status and price details</div>
            <RadioGroup
                row
                value={item.occupancy}
                onChange={(e, value) => props.updateItemOccupancy({ id: item.id, value: e.target.value, additionalAmounts: item.additional_amounts })}
            >
                <span>
                    <span className={classes.radioGroupLabel}>Occupancy</span>
                    <FormControlLabel value='single' control={<Radio />} label='Single' />
                    <FormControlLabel value='double' control={<Radio />} label='Double' />
                    <FormControlLabel value='triple' control={<Radio />} label='Triple' />
                    <FormControlLabel value='quad' control={<Radio />} label='Quad' />
                    <FormControlLabel value='golf_only' control={<Radio />} label='Golf only' />
                    <FormControlLabel value='other' control={<Radio />} label='Other' />
                </span>
                {item.occupancy === 'other' &&
                    <TextField
                        label='Other occupancy'
                        value={item.other_occupancy}
                        onChange={(e) => updateItem({ target: 'other_occupancy', value: e.target.value, id: item.id })}
                        required
                        size='small'
                    />
                }
            </RadioGroup>
            <RadioGroup
                row
                value={item.status}
                onChange={(e, value) => updateItem({ target: 'status', value: value, id: item.id })}
            >
                <span>
                    <span className={classes.radioGroupLabel}>Status</span>
                    <FormControlLabel value='1' control={<Radio />} label='Pending' />
                    <FormControlLabel value='2' control={<Radio />} label='Confirmed' />
                </span>
            </RadioGroup>
            <RadioGroup
                row
                value={item.published}
                onChange={(e, value) => updateItem({ target: 'published', value: value, id: item.id })}
            >
                <span>
                    <span className={classes.radioGroupLabel}>Published</span>
                    <FormControlLabel value='0' control={<Radio />} label='Not Published' />
                    <FormControlLabel value='1' control={<Radio />} label='Published' />
                </span>
            </RadioGroup>
            <Grid container spacing={2} className={classes.statusAndPriceInputContainer}>
                <Grid item>
                    <InputLabel className={classes.statusAndPriceLabel}>Num. golfers</InputLabel>
                    <Select
                        className={classes.statusAndPriceInput}
                        menuPortalTarget={document.body}
                        placeholder={'Num. golfers'}
                        value={item.number_golfers}
                        onChange={(value) => updateItem({ target: 'number_golfers', value: value, id: item.id })}
                        options={numberOfGolfersOptions}
                        searchable={true}
                    />
                </Grid>
                <Grid item>
                    <InputLabel className={classes.statusAndPriceLabel}>Deposit price</InputLabel>
                    {
                        item.price_as_deposit ?
                            <TextField
                                key='deposit_price'
                                value={item.totals.total_rounded}
                                InputProps={{ readOnly: true }}
                                className={classes.statusAndPriceInput}
                                size='small'
                                error={item.totals.total_rounded == 0}
                            />
                            :
                            <TextField
                                key='deposit_price_as_total'
                                value={item.price == 0 ? '' : item.price}
                                onChange={(e) => updateItem({ target: 'price', value: e.target.value, id: item.id })}
                                required
                                className={classes.statusAndPriceInput}
                                size='small'
                                error={item.price == 0}
                            />
                    }
                </Grid>
                <Grid item>
                    <InputLabel className={classes.statusAndPriceLabel}>Deposit Per</InputLabel>
                    <Select
                        className={classes.statusAndPriceInput}
                        menuPortalTarget={document.body}
                        placeholder={'Deposit per'}
                        value={item.deposit_per}
                        onChange={(value) => updateItem({ target: 'deposit_per', value: value, id: item.id })}
                        options={[
                            { value: 'per_golfer', label: 'per golfer' },
                            { value: 'per_couple', label: 'per couple' }
                        ]}
                        searchable={true}
                    />
                </Grid>
                <Grid item>
                    <InputLabel className={classes.statusAndPriceLabel}>Currency</InputLabel>
                    <Select
                        className={classes.statusAndPriceInput}
                        menuPortalTarget={document.body}
                        placeholder={'Currency'}
                        value={item.currency}
                        onChange={(value) => updateItem({ target: 'currency', value: value, id: item.id })}
                        options={currencyOptions}
                        searchable={true}
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel label='Equal with price' className={classes.statusAndPriceCheckbox}
                    control={
                        <Checkbox
                            checked={item.price_as_deposit}
                            onChange={(e) => updateItem({ target: 'price_as_deposit', value: e.target.checked, id: item.id })}
                        />
                    } />
                </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.statusAndPriceInputContainer}>
                <Grid item>
                    <InputLabel className={classes.statusAndPriceLabel}>Num. non golfers</InputLabel>
                    <Select
                        className={classes.statusAndPriceInput}
                        menuPortalTarget={document.body}
                        placeholder={'Num. non golfers'}
                        value={item.number_non_golfers}
                        onChange={(value) => updateItem({ target: 'number_non_golfers', value: value, id: item.id })}
                        options={numberOfGolfersOptions}
                        searchable={true}
                    />
                </Grid>
                <Grid item>
                    <InputLabel className={classes.statusAndPriceLabel}>Price</InputLabel>
                    <TextField
                        key='total_price'
                        value={item.totals.total_rounded}
                        InputProps={{ readOnly: true }}
                        className={classes.statusAndPriceInput}
                        size='small'
                        type='number'
                    />
                </Grid>
                <Grid item>
                    <InputLabel className={classes.statusAndPriceLabel}>Price per</InputLabel>
                    <Select
                        className={classes.statusAndPriceInput}
                        menuPortalTarget={document.body}
                        placeholder={'Price per'}
                        value={item.total_price_per}
                        onChange={(value) => updateItem({ target: 'total_price_per', value: value, id: item.id })}
                        options={[
                            { value: "per_golfer", label: "per golfer" },
                            { value: "per_couple", label: "per couple" },
                        ]}
                        searchable={true}
                    />
                </Grid>
                <Grid item>
                    <InputLabel className={classes.statusAndPriceLabel}>Due days</InputLabel>
                    <TextField
                        value={item.due_days}
                        onChange={(e) => updateItem({ target: 'due_days', value: e.target.value, id: item.id })}
                        className={classes.statusAndPriceInput}
                        size='small'
                        type='number'
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel label='Show Total Package Price' control={
                        <Checkbox
                            checked={item.show_tpp}
                            onChange={(e) => updateItem({ target: 'show_tpp', value: e.target.checked, id: item.id })}
                        />
                    } />
                </Grid>
            </Grid>
            <Grid>
                <Grid item>
                    <FormControlLabel label='Unlimited golf' control={
                        <Checkbox
                            checked={item.unlimited_rounds}
                            onChange={(e) => updateItem({ target: 'unlimited_rounds', value: e.target.checked, id: item.id })}
                        />
                    } />
                </Grid>
            </Grid>
            <AdditionalAmounts
                items={item.additional_amounts}
                itemOccupancy={item.occupancy}
                golfVacationItemId={item.id}
            />

            <div className={classes.golfVacationItemSection}>Totals</div>
            <Totals id={item.id} itemOccupancy={item.occupancy} additionalAmounts={item.additional_amounts} totals={item.totals} />

            <div className={classes.golfVacationItemSection}>
                {item.occupancy == 'golf_only' ? 'Lodging - golf only' : 'Lodging'}
                {item.occupancy !== 'golf_only' &&
                    <Switch
                        checked={lodgingsCollapsed}
                        onChange={() => setLodgingsCollapsed(prevState => !prevState)}
                    />
                }
            </div>
            {item.occupancy != 'golf_only' &&
                <Collapse in={lodgingsCollapsed}>
                    <Lodging
                        itemId={item.id}
                        itemDate={item.date_of_arrival_and_departure}
                        itemPackage={selectedPackage}
                        updatePackage={updatePackage}
                        contentBox={item.content_box_lodging}
                        nrGolfers={item.number_golfers.value}
                    />
                </Collapse>
            }
            <div className={classes.golfVacationItemSection}>
                Courses
                <Switch
                    checked={coursesCollapsed}
                    onChange={() => setCoursesCollapsed(prevState => !prevState)}
                />
            </div>
            <Collapse in={coursesCollapsed}>
                <Courses
                    itemId={item.id}
                    itemDate={item.date_of_arrival_and_departure}
                    itemPackage={selectedPackage}
                    updatePackage={updatePackage}
                    contentBox={item.content_box}
                    maxGolfers={item.number_golfers.value}
                />
            </Collapse>
            <div className={classes.golfVacationItemSection}>Descriptions</div>
            <Descriptions
                id={item.id}
                description={item.description}
                confirmation_id={item.confirmation_id}
                resort_title={item.resort_title}
                check_in_location={item.check_in_location}
                reservation_name={item.reservation_name}
            />
        </div>

    );
}

const enhance = compose(
    connect(
        state => ({
            resortPackages: state.packages.resortPackages,
        }),
        dispatch => ({
            updateItem(payload) {
                dispatch(quotesActions.updateGolfVacationItem(payload));
            },
            updateItemOccupancy(payload) {
                dispatch(quotesActions.updateGolfVacationItemOccupancy(payload));
            },
            replaceItem(payload) {
                dispatch(quotesActions.replaceGolfVacationItem(payload));
            },
            refreshName(payload) {
                dispatch(quotesActions.refreshGolfVacationItemName(payload));
            }
        })
    )
)

export default enhance(GolfVacationItemInfo);