const styles = theme => ({

    actionButtons: {
        minWidth: 120,
        display: 'flex',
        justifyContent: 'center'
    },
    topTooblar: {
        width: '100%',
        backgroundColor: 'red',
        '& div': {
            width: '100%',
            backgroundColor: 'blue'
        },
    },
    internalActions: {
        marginTop: 10,
        display: 'flex',
        width: '100%',
    },
    searchContainer: {
        minWidth: 200,
        flex: 2,
    },
    searchInputWithFilters: {
        width: '45% !important'
    },
    searchInputWithoutFilters: {
        width: '25% !important'
    },
    searchIcon: {
        flex: 1
    },
    filterToggleButton: {
        marginLeft: '8px !important',
        height: 40
    },
    dateRangeContainer: {
        minWidth: 300,
        flex: 1
    },
    dateRangeCalendar: {
        zIndex: 100,
        position: 'fixed',
        width: '350px !important'
    },
    dateRangeInput: {
        height: 40,
        borderColor: 'rgba(0,0,0,0.23)',
        width: '90% !important',
        '& div': {
            borderRadius: 4,
            borderColor: 'rgba(0,0,0,0.23)',
        }
    },
    dateRangeIcon: {
        color: 'rgba(0, 0, 0, 0.54)'
    },
    filterInput: {
        width: '90% !important',
        marginBottom: '10px !important',
    },
    statusFiltersContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 0.75
    },
    filterTypeChip: {
        width: '40% !important',
        height: 'unset !important'
    },
    filterTypeBox: {
        display: 'flex'
    }
});

export default styles;