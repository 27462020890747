import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import { coursesService, emailsService, lodgingsService, quotesService } from '../services';
import { push } from "redux-first-history";
import {
    quotesActions,
    quotesTypes,
} from '../redux';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { calculateTotals, getNights, getRounds, sortCoursesByDate } from '../config';
import { buildGolfVacationItemPayload } from '../normalizers/GolfVacationItemNormalizer';

const getQuoteById = function* (action) {
    try {
        const response = yield call(quotesService.getQuoteById, action.payload);
        if (response.status === 200) {
            let data = response.data;
            let payload = buildGetQuoteByIdPayload(data);
            yield put(quotesActions.getQuoteByIdSuccess(payload));
        }
        else {
            toastr.error('', 'Quote not found');
        }
    } catch (e) {
        console.log(e);
        yield put(quotesActions.getQuoteByIdFailed());
    }
};

const buildGetQuoteByIdPayload = (data) => {
    let quoteInfo = {
        title: data.title,
        email: data.email,
        seoLink: data.seo_slug,
        seoTitle: data.seo_title,
        seoKeywords: data.seo_keywords,
        seoDescription: data.seo_description,
        suggestionContent: data.suggestion_content,
        initialContent: data.suggestionContent,
        //FIXME: Update quoteUnderReviewFlag when the endpoint is updated
        quoteUnderReview: 0
    };
    let emailInfo = {
        confirmed: data.confirmed,
        email: data.email,
        name: data.golf_vacation_items && data.golf_vacation_items.length > 0 ? data.golf_vacation_items[0].first_name : '',
        link: data.seo_slug
    };

    let selectedRooms = {};
    let selectedCourses = {};
    let golfVacationItems = data.golf_vacation_items.map(item => {
        item.date_of_arrival_and_departure = getDateOfArrivalAndDeparture(item);

        item.lodging.forEach(ldg => {
            if (ldg.nights === '') {
                ldg.nights = 1;
            }
            if (ldg.units === '') {
                ldg.units = 1;
            }
        });
        selectedRooms[item.id] = item.lodging;

        selectedCourses[item.id] = item.courses.map(course => ({ ...course, tbd: course.tbd ? 'on' : 'off', titleOpen: false }));
        selectedCourses[item.id] = sortCoursesByDate(selectedCourses[item.id]);

        item.nights = getNights(item.date_of_arrival, item.date_of_departure);
        item.rounds = getRounds(selectedCourses[item.id]);

        let lodgingList;
        if (item.occupancy === 'golf_only') {
            lodgingList = [];
        } else {
            lodgingList = selectedRooms[item.id];
        }
        item.totals = calculateTotals({
            selectedCourses: selectedCourses[item.id],
            selectedRooms: lodgingList,
            additionalAmounts: item.additional_amounts
        });

        delete item.lodging;
        delete item.courses;

        return item;
    });

    let payload = {
        quoteInfo: quoteInfo,
        emailInfo: emailInfo,
        password: data.password,
        golfVacationItems: golfVacationItems,
        selectedRooms: selectedRooms,
        selectedCourses: selectedCourses
    }
    return payload;
}

const getDateOfArrivalAndDeparture = (item) => {
    let dateOfArrivalAndDeparture = [];
    const dateOfArrival = item.date_of_arrival;
    const dateOfDeparture = item.date_of_departure;
    if (dateOfArrival && dateOfDeparture) {
        dateOfArrivalAndDeparture = [dateOfArrival, dateOfDeparture];
    }
    return dateOfArrivalAndDeparture;
}

const saveQuote = function* (action) {
    try {
        const response = yield call(quotesService.saveQuote, action.payload);
        console.log(response);
        yield put(push(`/golf-vacation/${response.data.id}`));
        yield put(quotesActions.saveQuoteSuccess(response.data));
        toastr.success('', 'The quote has been created');

    } catch (e) {
        console.log(e);
        yield put(quotesActions.saveQuoteFailed());
        toastr.error('', 'Something went wrong');
    }
};

const updateQuote = function* (action) {
    try {
        const response = yield call(quotesService.updateQuote, action.payload);
        let data = response.data;
        let emailInfo = {
            confirmed: data.confirmed,
            email: data.email,
            name: data.golf_vacation_items && data.golf_vacation_items.length > 0 ? data.golf_vacation_items[0].first_name : '',
            link: data.seo_slug
        };
        yield put(quotesActions.updateQuoteSuccess({ emailInfo }));
        toastr.success('', 'The quote has been updated');

    } catch (e) {
        console.log(e);
        yield put(quotesActions.updateQuoteFailed());
        toastr.error('', 'Something went wrong');
    }
};

const deleteQuote = function* (action) {
    try {
        const payload = { id: action.payload.id };
        yield call(quotesService.deleteQuote, payload);
        yield put(quotesActions.deleteQuoteSuccess());
        toastr.success('', 'The quote has been deleted');

        if (action.payload.section === 'DUE') {
            yield put(quotesActions.getDueQuotes());
        } else {
            const mainQuotesState = yield select((state) => state.quotes.mainQuotes);
            let params = bulidGetQuotesParams(mainQuotesState);
            yield put(quotesActions.getQuotes(params))
        }
    } catch (e) {
        console.log(e);
        yield put(quotesActions.deleteQuoteFailed());
        toastr.error('', 'Something went wrong');
    }
};

const hideQuote = function* (action) {
    try {
        yield call(quotesService.hideQuote, action.payload);
        yield put(quotesActions.hideQuoteSuccess());
        toastr.success('', 'The quote has been hidden');

        yield put(quotesActions.getDueQuotes());
    } catch (e) {
        console.log(e);
        yield put(quotesActions.hideQuoteFailed());
        toastr.error('', 'Something went wrong');
    }
};

const bulidGetQuotesParams = (mainQuotesState) => {
    let params = { page: mainQuotesState.page, perPage: mainQuotesState.perPage };
    const globalSearch = localStorage.getItem('quotesSearch');
    const publishedFilterValue = localStorage.getItem('quotesPublishedFilter');
    const confirmedFilterValue = localStorage.getItem('quotesConfirmedFilter');
    let dateRangeFilterValue = localStorage.getItem('quotesDateRangeFilter');
    dateRangeFilterValue = dateRangeFilterValue ? JSON.parse(dateRangeFilterValue).map(e => new Date(e)) : [];
    let typeFilterValue = localStorage.getItem('quotesTypeFilter');
    typeFilterValue = typeFilterValue ? JSON.parse(typeFilterValue) : [];

    if (globalSearch) {
        params.search = globalSearch;
    }

    if (typeFilterValue.includes('Course')) {
        params.courses = true;
    }

    if (typeFilterValue.includes('Lodging')) {
        params.lodgings = true;
    }

    if (dateRangeFilterValue.length > 1) {
        params.startDate = moment(dateRangeFilterValue[0]).format('YYYY-MM-DD');
        params.endDate = moment(dateRangeFilterValue[1]).format('YYYY-MM-DD');
    }

    if (publishedFilterValue) {
        params.published = publishedFilterValue === 'published' ? true : false;
    }

    if (confirmedFilterValue) {
        params.confirmed = confirmedFilterValue === 'confirmed' ? true : false;
    }

    return params;
}

const getQuotes = function* (action) {
    try {
        const response = yield call(quotesService.getQuotes, action.payload);
        yield put(quotesActions.getQuotesSuccess(response.data));
    } catch (e) {
        console.log(e);
        yield put(quotesActions.getQuotesFailed());
    }
};

const setQuoteConfirmFlag = function* (action) {
    try {
        yield call(quotesService.setQuoteConfirmFlag, action.payload);
        yield put(quotesActions.setQuoteConfirmFlagSuccess());
        toastr.success('', 'Confirm flag toggled successfully');

        const mainQuotesState = yield select((state) => state.quotes.mainQuotes);
        let params = bulidGetQuotesParams(mainQuotesState);
        yield put(quotesActions.getQuotes(params))
    } catch (e) {
        console.log(e);
        yield put(quotesActions.setQuoteConfirmFlagFailed());
        toastr.error('', 'Something went wrong');
    }
};

const setQuotePublishFlag = function* (action) {
    try {
        yield call(quotesService.setQuotePublishFlag, action.payload);
        yield put(quotesActions.setQuotePublishFlagSuccess());
        toastr.success('', 'Publish flag toggled successfully');

        const mainQuotesState = yield select((state) => state.quotes.mainQuotes);
        let params = bulidGetQuotesParams(mainQuotesState);
        yield put(quotesActions.getQuotes(params))
    } catch (e) {
        console.log(e);
        yield put(quotesActions.setQuotePublishFlagFailed());
        toastr.error('', 'Something went wrong');
    }
};

const setQuoteUnderReviewFlag = function* (action) {
    try {
        console.log(action.payload);
        yield call(quotesService.setQuoteUnderReviewFlag, action.payload);
        yield put(quotesActions.setQuoteUnderReviewFlagSuccess({flag: action.payload.flag}));
    } catch (e) {
        console.log(e);
        toastr.error('', 'Something went wrong ');
    }
};

const getDueQuotes = function* (action) {
    try {
        const response = yield call(quotesService.getDueQuotes);
        yield put(quotesActions.getDueQuotesSuccess(response.data));
    } catch (e) {
        console.log(e);
        yield put(quotesActions.getDueQuotesFailed());
    }
};

const getTeeTimeReminders = function* (action) {
    try {
        const response = yield call(quotesService.getTeeTimeReminders, action.payload);
        yield put(quotesActions.getTeeTimeRemindersSuccess(response.data));
    } catch (e) {
        console.log(e);
        yield put(quotesActions.getTeeTimeRemindersFailed());
    }
};

const getPaidQuotes = function* (action) {
    try {
        const response = yield call(quotesService.getPaidQuotes);
        yield put(quotesActions.getPaidQuotesSuccess(response.data));
    } catch (e) {
        console.log(e);
        yield put(quotesActions.getPaidQuotesFailed());
    }
};

const downloadConfirmationPdf = function* (action) {
    try {
        const response = yield call(quotesService.downloadConfirmationPdf, action.payload);
        let contentDisposition = response.headers['content-disposition']
        const filename = contentDisposition.substring(
            contentDisposition.indexOf('"') + 1,
            contentDisposition.lastIndexOf('"')
        );

        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        toastr.success('', 'Confirmation pdf downloaded successfully');

    } catch (e) {
        console.log(e);
        toastr.error('', 'Error while downloading confirmation pdf')
    }
};

const sendConfirmationPdf = function* (action) {
    try {
        yield call(emailsService.sendConfirmationPdf, action.payload);
        toastr.success('', 'Confirmation PDF sent successfully');
    } catch (e) {
        console.log(e);
        toastr.error('', 'Error while sending the confirmation PDF');
    }
};

const sendVoucher = function* (action) {
    try {
        yield call(emailsService.sendVoucher, action.payload);
        yield put(quotesActions.sendVoucherSuccess(action.payload));
        toastr.success('', 'The voucher was successfully sent');
    } catch (e) {
        console.log(e);
        toastr.error('', 'Error while sending the voucher');
    }
};

const sendFollowupEmail = function* (action) {
    try {
        yield call(emailsService.sendFollowupEmail, action.payload);
        yield put(quotesActions.sendFollowupEmailSuccess(action.payload));
        toastr.success('', 'Followup email sent successfully');
    } catch (e) {
        console.log(e);
        toastr.error('', 'Error while sending the followup email');
    }
};

const setFollowupFlag = function* (action) {
    try {
        console.log(action.payload);
        yield call(quotesService.setFollowupFlag, action.payload);
        yield put(quotesActions.setFollowupFlagSuccess(action.payload));
    } catch (e) {
        console.log(e);
        toastr.error('', 'Something went wrong ');
    }
};

const sendPaymentReminderEmail = function* (action) {
    try {
        yield call(emailsService.sendPaymentReminderEmail, action.payload);
        yield put(quotesActions.sendPaymentReminderEmailSuccess(action.payload));
        toastr.success('', 'Payment reminder email sent successfully');
    } catch (e) {
        console.log(e);
        toastr.error('', 'Error while sending the payment reminder email');
    }
};

const sendPreTripFollowupEmail = function* (action) {
    try {
        yield call(emailsService.sendPreTripFollowupEmail, action.payload);
        toastr.success('', 'Pre trip followup email sent successfully');
    } catch (e) {
        console.log(e);
        toastr.error('', 'Error while sending the pre trip followup email');
    }
};

const sendAfterTripFollowupEmail = function* (action) {
    try {
        yield call(emailsService.sendAfterTripFollowupEmail, action.payload);
        yield put(quotesActions.sendAfterTripFollowupEmailSuccess(action.payload));
        toastr.success('', 'After trip followup email sent successfully');
    } catch (e) {
        console.log(e);
        toastr.error('', 'Error while sending the after trip followup email');
    }
};

const sendThankYouEmail = function* (action) {
    try {
        yield call(emailsService.sendThankYouEmail, action.payload);
        yield put(quotesActions.sendThankYouEmailSuccess(action.payload));
        toastr.success('', 'Thank you email sent successfully');
    } catch (e) {
        console.log(e);
        toastr.error('', 'Error while sending the thank you email');
    }
};

const sendGenericEmail = function* (action) {
    try {
        yield call(emailsService.sendGenericEmail, action.payload);
        toastr.success('', 'The email was successfully sent');
    } catch (e) {
        console.log(e);
        toastr.error('', 'Error while sending the email');
    }
};

const sendConfirmationEmail = function* (action) {
    try {
        yield call(emailsService.sendConfirmationEmail, action.payload);
        toastr.success('', 'The confirmation email was successfully sent');
    } catch (e) {
        console.log(e);
        toastr.error('', 'Error while sending the confirmation email');
    }
};

const addGolfVacationItem = function* (action) {
    try {
        const response = yield call(quotesService.addGolfVacationItem, action.payload);

        let item = response.data;
        item.date_of_arrival_and_departure = getDateOfArrivalAndDeparture(item);
        item.nights = getNights(item.date_of_arrival, item.date_of_departure);
        item.rounds = 0;
        item.totals = calculateTotals({
            selectedCourses: [],
            selectedRooms: [],
            additionalAmounts: item.additional_amounts
        });
        yield put(quotesActions.addGolfVacationItemSuccess({ item }));
    } catch (e) {
        console.log(e);
        toastr.error('', 'Something went wrong!');
    }
};
const cloneGolfVacationItem = function* (action) {
    yield put(quotesActions.cloneGolfVacationItemRequest());

    try {
        const selectedCoursesToBeSaved = yield select((state) => state.quotes.selectedCourses[action.payload.id]);
        let golfVacationItemPayload = buildGolfVacationItemPayload(action.payload.item, action.payload.quoteId, action.payload.indexToClone, selectedCoursesToBeSaved);
        try {
            yield call(quotesService.updateGolfVacationItem, { id: action.payload.id, body: golfVacationItemPayload });
        } catch (ex) {
            console.log(ex);
            toastr.error('The initial golf vacation could not be saved');
        }

        const response = yield call(quotesService.cloneGolfVacationItem, action.payload);
        let item = response.data;
        item.date_of_arrival_and_departure = getDateOfArrivalAndDeparture(item);
        item.nights = getNights(item.date_of_arrival, item.date_of_departure);

        let selectedRooms = item.lodging;
        let selectedCourses = item.courses.map(course => ({ ...course, tbd: course.tbd ? 'on' : 'off', titleOpen: false }));
        selectedCourses = sortCoursesByDate(selectedCourses);

        item.rounds = 0;
        item.totals = calculateTotals({
            selectedCourses: [],
            selectedRooms: [],
            additionalAmounts: item.additional_amounts
        });
        yield put(quotesActions.cloneGolfVacationItemSuccess({ index: action.payload.index, item, selectedRooms, selectedCourses }));
    } catch (e) {
        console.log(e);
        toastr.error('', 'Something went wrong!');
        yield put(quotesActions.cloneGolfVacationItemFailed());
    }
};
const deleteGolfVacationItem = function* (action) {
    try {
        yield call(quotesService.deleteGolfVacationItem, action.payload);
        yield put(quotesActions.deleteGolfVacationItemSuccess(action.payload));
        toastr.success('', 'The golf vacation item was successfully deleted');
    } catch (e) {
        console.log(e);
        toastr.error('', 'Something went wrong!');
    }
};

const addSelectedRoom = function* (action) {
    try {
        const response = yield call(lodgingsService.addLodging, action.payload);
        yield put(quotesActions.addSelectedRoomSuccess({ itemId: action.payload.itemId, newEntry: { ...response.data, package: action.payload.package } }));
    } catch (e) {
        console.log(e);
        toastr.error('', 'Something went wrong');
    }
};

const saveSelectedRoom = function* (action) {
    try {
        yield call(lodgingsService.updateLodging, action.payload.body);
    } catch (e) {
        console.log(e);
        toastr.error('', 'Something went wrong');
    }
};

const deleteSelectedRoom = function* (action) {
    try {
        yield call(lodgingsService.deleteLodging, action.payload);
        yield put(quotesActions.deleteSelectedRoomSuccess(action.payload));
    } catch (e) {
        console.log(e);
        toastr.error('', 'Something went wrong');
    }
};

const saveSelectedCourse = function* (action) {
    try {
        yield call(coursesService.updateCourse, action.payload.body);
    } catch (e) {
        console.log(e);
        toastr.error('', 'Something went wrong');
    }
};

export default function* quotesSaga() {
    yield all([
        takeLatest(quotesTypes.GET_QUOTE_BY_ID, getQuoteById),
        takeLatest(quotesTypes.SAVE_QUOTE, saveQuote),
        takeLatest(quotesTypes.UPDATE_QUOTE, updateQuote),
        takeLatest(quotesTypes.DELETE_QUOTE, deleteQuote),
        takeLatest(quotesTypes.HIDE_QUOTE, hideQuote),
        takeLatest(quotesTypes.GET_QUOTES, getQuotes),
        takeLatest(quotesTypes.GET_DUE_QUOTES, getDueQuotes),
        takeLatest(quotesTypes.GET_TEE_TIME_REMINDERS, getTeeTimeReminders),
        takeLatest(quotesTypes.GET_PAID_QUOTES, getPaidQuotes),
        takeLatest(quotesTypes.SET_QUOTE_CONFIRM_FLAG, setQuoteConfirmFlag),
        takeLatest(quotesTypes.SET_QUOTE_PUBLISH_FLAG, setQuotePublishFlag),
        takeLatest(quotesTypes.SET_QUOTE_UNDER_REVIEW_FLAG, setQuoteUnderReviewFlag),
        takeLatest(quotesTypes.DOWNLOAD_CONFIRMATION_PDF, downloadConfirmationPdf),
        takeLatest(quotesTypes.SEND_CONFIRMATION_PDF, sendConfirmationPdf),
        takeLatest(quotesTypes.SEND_VOUCHER, sendVoucher),
        takeLatest(quotesTypes.SEND_FOLLOWUP_EMAIL, sendFollowupEmail),
        takeLatest(quotesTypes.SET_FOLLOWUP_FLAG, setFollowupFlag),
        takeLatest(quotesTypes.SEND_PAYMENT_REMINDER_EMAIL, sendPaymentReminderEmail),
        takeLatest(quotesTypes.SEND_PRE_TRIP_FOLLOWUP_EMAIL, sendPreTripFollowupEmail),
        takeLatest(quotesTypes.SEND_AFTER_TRIP_FOLLOWUP_EMAIL, sendAfterTripFollowupEmail),
        takeLatest(quotesTypes.SEND_THANK_YOU_EMAIL, sendThankYouEmail),
        takeLatest(quotesTypes.SEND_GENERIC_EMAIL, sendGenericEmail),
        takeLatest(quotesTypes.SEND_CONFIRMATION_EMAIL, sendConfirmationEmail),
        takeLatest(quotesTypes.ADD_GOLF_VACATION_ITEM, addGolfVacationItem),
        takeLatest(quotesTypes.CLONE_GOLF_VACATION_ITEM, cloneGolfVacationItem),
        takeLatest(quotesTypes.DELETE_GOLF_VACATION_ITEM, deleteGolfVacationItem),
        takeLatest(quotesTypes.ADD_SELECTED_ROOM, addSelectedRoom),
        takeLatest(quotesTypes.SAVE_SELECTED_ROOM, saveSelectedRoom),
        takeLatest(quotesTypes.DELETE_SELECTED_ROOM, deleteSelectedRoom),
        takeLatest(quotesTypes.SAVE_SELECTED_COURSE, saveSelectedCourse),
    ]);
}