import React, { useState, useCallback, useEffect } from 'react';
import GolfVacationItemCard from './GolfVacationItemCard.js';
import { compose } from 'redux';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import { useClasses } from '../../../../config/styles.js';
import styles from './styles.js';
import { Button, LinearProgress } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { quotesActions } from '../../../../redux/index.js';

const GolfVacationItems = (props) => {

    const { quoteId, items, setItems, addItem, cloneItem } = props;
    const [activeIndex, setActiveIndex] = useState(-1);
    const classes = useClasses(styles);

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setItems({
            items:
                update(items, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, items[dragIndex]]
                    ],
                })
        });
        if (activeIndex !== -1) {
            setActiveIndex(hoverIndex)
        }
    }, [activeIndex, items]);

    useEffect(() => {
        if (activeIndex !== -1) {
          const scrollToElement = () => {
            const element = document.getElementById(`item-${activeIndex}`);
            if (element) {
              const elementRect = element.getBoundingClientRect();
              const absoluteElementTop = elementRect.top + window.pageYOffset;
              window.scrollTo({
                top: absoluteElementTop,
                behavior: 'instant',
              });
            }
          };
    
          requestAnimationFrame(scrollToElement);
        }
      }, [activeIndex, items.length]);

    const setActivePanel = (index) => {
        if (index === activeIndex) {
            setActiveIndex(-1);
        } else {
            setActiveIndex(index);
        }
    }

    const duplicateCard = (id, indexToClone, item) => {
        let index = indexToClone + 1;
        cloneItem({ id, index, indexToClone, quoteId, item });
        setActiveIndex(index);
    }

    const addGolfVacationItem = () => {
        let userId = localStorage.getItem('userId');
        let payload = { vacationId: quoteId, modifiedById: userId };
        addItem(payload);
    }

    return (
        <div>
            {
                props.cloneInProgress && <LinearProgress />
            }
            {items.map((card, index) => {
                let isActive = index === activeIndex;
                return <div key={card.id} id={`item-${index}`}>
                    <GolfVacationItemCard
                        key={card.id}
                        index={index}
                        id={card.id}
                        item={card}
                        moveCard={moveCard}
                        duplicateItem={duplicateCard}
                        classes={classes}
                        isActive={isActive}
                        onClickAction={() => setActivePanel(index)}
                    />
                </div>
            })}
            {
                quoteId ?
                    <Button fullWidth className={classes.addVacationItemButton} onClick={addGolfVacationItem}>
                        ADD VACATION ITEM <AddBoxIcon className={classes.addVacationItemIcon} />
                    </Button>
                    :
                    <Button fullWidth className={classes.addVacationItemButton}>
                        Golf vacation items can be added after the vacation has been saved
                    </Button>
            }
        </div>
    );
}

const enhance = compose(
    connect(
        state => ({
            cloneInProgress: state.quotes.cloneInProgress
        }),
        dispatch => ({
            addItem(payload) {
                dispatch(quotesActions.addGolfVacationItem(payload));
            },
            cloneItem(payload) {
                dispatch(quotesActions.cloneGolfVacationItem(payload));
            },
            setItems(payload) {
                dispatch(quotesActions.setGolfVacationItems(payload));
            },
        })
    )
);

export default enhance(GolfVacationItems);