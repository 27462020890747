import axios from 'axios';

export const getQuoteById = payload => axios.get(`api/quotes/${payload.id}`);

export const saveQuote = payload => axios.post('api/quotes/save', payload);

export const updateQuote = payload => axios.put(`api/quotes/${payload.id}/update`, payload.data);

export const setQuoteConfirmFlag = payload => axios.put(`api/quotes/${payload.id}/confirm/${payload.flag}`);

export const setQuotePublishFlag = payload => axios.put(`api/quotes/${payload.id}/publish/${payload.flag}`);

export const setQuoteUnderReviewFlag = payload => axios.put(`api/quotes/${payload.id}/review/${payload.flag}`);

export const setFollowupFlag = payload => axios.put(`api/quotes/followup/${payload.id}`, payload.body);

export const slugify = payload => axios.post('api/quotes/slugify', payload);

export const deleteQuote = payload => axios.delete(`api/quotes/${payload.id}`);

export const hideQuote = payload => axios.put(`api/quotes/${payload.id}/hide`);

export const getQuotePreview = payload => axios.get(`api/quotes/${payload.id}/preview`);

export const getQuotePayments = payload => axios.get(`api/quotes/${payload.id}/payments`);

export const getQuotes = payload => axios.get('/api/quotes', { params: payload});

export const getDueQuotes = payload => axios.get('api/quotes/due');

export const getPaidQuotes = payload => axios.get('api/quotes/paid');

export const getTeeTimeReminders = payload => axios.get('api/quotes/tee-time-reminder');

export const addGolfVacationItem = payload => axios.post('api/quotes/item/save', payload);

export const updateGolfVacationItem = payload => axios.put(`api/quotes/item/${payload.id}/update`, payload.body);

export const cloneGolfVacationItem = payload => axios.post(`api/quotes/item/${payload.id}/clone`);

export const deleteGolfVacationItem = payload => axios.delete(`api/quotes/item/${payload.id}/delete`);

export const setGolfVacationItemConfirmFlag = payload => axios.put(`api/quotes/item/${payload.id}/confirm/${payload.flag}`);

export const setGolfVacationItemPublishFlag = payload => axios.put(`api/quotes/item/${payload.id}/publish/${payload.flag}`);

export const downloadConfirmationPdf = payload => axios.get(`api/pdf/download/confirmation/${payload.id}`, {responseType: 'blob'});