import React, { Fragment, useState } from 'react';
import { AppBar, Toolbar, Drawer, List, IconButton, ListItem, ListItemIcon, ListItemText, ListItemButton, Tooltip } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import ViewListIcon from '@mui/icons-material/ViewList';
import AddIcon from '@mui/icons-material/Add';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import SnoozeIcon from '@mui/icons-material/Snooze';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PaymentIcon from '@mui/icons-material/Payment';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DescriptionIcon from '@mui/icons-material/Description';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LogoutIcon from '@mui/icons-material/Logout';
import { useClasses } from '../../config';
import styles from './styles';

const Menu = (props) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const logout = () => {
        localStorage.clear();
        navigate('/login');
    }

    const classes = useClasses(styles)
    return (
        <Fragment>
            <AppBar position='fixed' className={clsx(classes.appBar, { [classes.appBarShift]: open })}>
                <Toolbar className={classes.appToolbar}>
                    <Tooltip title="Logout">
                        <IconButton onClick={logout}>
                            <LogoutIcon className={classes.logoutIcon} />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>
            <Drawer variant='permanent'
                className={clsx({ ['drawerScrollbar']: true, [classes.drawer]: true, [classes.drawerOpen]: open, [classes.drawerClose]: !open })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className='leftMenu'>
                    <div className={classes.menuButtonContainer}>
                        <IconButton onClick={() => setOpen(prevOpen => !prevOpen)} className={classes.menuButton}>
                            {open ? <ChevronLeftIcon /> : <MenuIcon />}
                        </IconButton>
                    </div>
                    <div className='fullWitdthMenuItemTitle'>
                        <span>{open ? "Quotes" : "Q"} </span>
                    </div>
                    <List>
                        <Link to='/quotes'>
                            <ListItem>
                                <Tooltip title='Quotes'><ListItemIcon><ViewListIcon /></ListItemIcon></Tooltip>
                                <ListItemText primary={'Quotes List'} />
                            </ListItem>
                        </Link>
                        <Link to='/golf-vacation'>
                            <ListItemButton className={classes.menuButton}>
                                <Tooltip title='New Quote'><ListItemIcon><AddIcon /></ListItemIcon></Tooltip>
                                <ListItemText primary={'New Quote'} />
                            </ListItemButton>
                        </Link>
                        <Link to='/quotes/due'>
                            <ListItemButton className={classes.menuButton}>
                                <Tooltip title='Due Quotes'><ListItemIcon><AccessAlarmIcon /></ListItemIcon></Tooltip>
                                <ListItemText primary={'Due Quotes List'} />
                            </ListItemButton>
                        </Link>
                        <Link to='/quotes/paid'>
                            <ListItemButton className={classes.menuButton}>
                                <Tooltip title='Paid Quotes'><ListItemIcon><SnoozeIcon /></ListItemIcon></Tooltip>
                                <ListItemText primary={'Paid Quotes List'} />
                            </ListItemButton>
                        </Link>
                        <Link to='/tee-time'>
                            <ListItemButton className={classes.menuButton}>
                                <Tooltip title='Tee Time Reminder'><ListItemIcon><NotificationsActiveIcon /></ListItemIcon></Tooltip>
                                <ListItemText primary={'Tee Time Reminder'} />
                            </ListItemButton>
                        </Link>
                    </List>
                    <div className='fullWitdthMenuItemTitle'>
                        <span>{open ? 'Leads' : 'L'} </span>
                    </div>
                    <List>
                        <Link to='/leads'>
                            <ListItemButton className={classes.menuButton}>
                                <ListItemIcon><ViewListIcon /></ListItemIcon>
                                <ListItemText primary={'Leads list'} />
                            </ListItemButton>
                        </Link>
                    </List>
                    <div className='fullWitdthMenuItemTitle'>
                        <span>{open ? 'Orders' : 'O'} </span>
                    </div>
                    <List>
                        <Link to='/orders'>
                            <ListItemButton className={classes.menuButton}>
                                <ListItemIcon><PaymentIcon /></ListItemIcon>
                                <ListItemText primary={'Orders List'} />
                            </ListItemButton>
                        </Link>
                    </List>
                    <div className='fullWitdthMenuItemTitle'>
                        <span>{open ? 'Pricing' : 'P'} </span>
                    </div>
                    <List>
                        <Link to='/pricing-system'>
                            <ListItemButton className={classes.menuButton}>
                                <ListItemIcon><LocalAtmIcon /></ListItemIcon>
                                <ListItemText primary={'Pricing system'} />
                            </ListItemButton>
                        </Link>
                        <Link to='/sales'>
                            <ListItemButton className={classes.menuButton}>
                                <ListItemIcon><AccountBalanceIcon /></ListItemIcon>
                                <ListItemText primary={'Sales'} />
                            </ListItemButton>
                        </Link>
                    </List>
                    <div className='fullWitdthMenuItemTitle'>
                        <span>{open ? 'Package Management' : 'PM'} </span>
                    </div>
                    <List>
                        <Link to='/manage-packages'>
                            <ListItemButton className={classes.menuButton}>
                                <ListItemIcon><DescriptionIcon /></ListItemIcon>
                                <ListItemText primary={'Edit packages'} />
                            </ListItemButton>
                        </Link>
                    </List>
                    <div className='fullWitdthMenuItemTitle'>
                        <span>{open ? 'Email Templates' : 'T'} </span>
                    </div>
                    <List>
                        <Link to='/templates'>
                            <ListItemButton className={classes.menuButton}>
                                <ListItemIcon><AlternateEmailIcon /></ListItemIcon>
                                <ListItemText primary={'Edit templates'} />
                            </ListItemButton>
                        </Link>
                    </List>
                </div>
            </Drawer>
        </Fragment>
    );
}

export default Menu;