import React from 'react';
import { useClasses } from '../../../../../config/styles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styles from './styles';
import { Grid, IconButton, TextField } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import { quotesActions } from '../../../../../redux';
import { calculateTotals } from '../../../../../config';


const AdditionalAmounts = (props) => {

    const { items, golfVacationItemId, itemOccupancy, updateItem, selectedCourses, selectedRooms } = props;
    const classes = useClasses(styles);

    const updateDescription = (value, index) => {
        let newList = [...items];
        newList[index] = value;

        updateItem({target: 'additional_amounts', value: newList, id: golfVacationItemId});
    }

    const updateAttribute = (value, index) => {
        let newList = [...items];
        newList[index] = value;

        //TODO: Move logic in Redux
        let lodgingList;
        if (itemOccupancy === 'golf_only') {
            lodgingList = [];
        } else {
            lodgingList = selectedRooms[golfVacationItemId]
        }
        let totals = calculateTotals({
            selectedCourses: selectedCourses[golfVacationItemId],
            selectedRooms: lodgingList,
            additionalAmounts: newList
        });

        //TODO: Merge the following actions
        updateItem({target: 'additional_amounts', value: newList, id: golfVacationItemId});
        updateItem({target: 'totals', value: totals, id: golfVacationItemId});
    }

    const addAmount = () => {
        let newList = [...items];
        newList.push({ amount: 0, description: '', markup: 0 });

        updateItem({target: 'additional_amounts', value: newList, id: golfVacationItemId});
    }

    const deleteAmount = (index) => {
        const newList = [...items];
        newList.splice(index, 1);

        //TODO: Move logic in Redux
        let lodgingList;
        if (itemOccupancy === 'golf_only') {
            lodgingList = [];
        } else {
            lodgingList = selectedRooms[golfVacationItemId]
        }
        let totals = calculateTotals({
            selectedCourses: selectedCourses[golfVacationItemId],
            selectedRooms: lodgingList,
            additionalAmounts: newList
        });

        //TODO: Merge the following actions
        updateItem({target: 'additional_amounts', value: newList, id: golfVacationItemId});
        updateItem({target: 'totals', value: totals, id: golfVacationItemId});
    }

    return (
        <div className={classes.additionalAmountsContainer}>
            <div className={classes.additionalAmountsSection}>
                <span className={classes.additionalAmountsTitle}>Addition Amounts</span>
                <span>
                    <IconButton onClick={addAmount}>
                        <AddBoxIcon sx={{color: 'black'}}/>
                    </IconButton>
                </span>
            </div>
            <div>
                {
                    items.map((item, index) => {
                        return <Grid key={index} container spacing={2} className={classes.additionalAmountRow}>
                            <Grid item>
                                <IconButton onClick={() => deleteAmount(index)}>
                                    <DeleteIcon sx={{color: 'black'}}/>
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <TextField
                                    label='Description'
                                    value={item.description}
                                    onChange={(e) => updateDescription({ ...item, description: e.target.value }, index)}
                                    size='small'
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    label='Amount'
                                    value={item.amount.toString()}
                                    onChange={(e) => updateAttribute({ ...item, amount: parseInt(e.target.value) || 0 }, index)}
                                    size='small'
                                    type='number'
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    label='Markup %'
                                    value={item.markup.toString()}
                                    onChange={(e) => updateAttribute({ ...item, markup: parseInt(e.target.value) || 0}, index)}
                                    size='small'
                                    type='number'
                                />
                            </Grid>
                            <Grid item className={classes.totalBox}>
                                <span>Total: </span>
                                <span></span>
                                <span>{(item.amount + item.markup / 100 * item.amount).toFixed(2)}</span>
                            </Grid>
                        </Grid>
                    })
                }
            </div>

        </div>
    );
}

const enhance = compose(
    connect(
        state => ({
            selectedCourses: state.quotes.selectedCourses,
            selectedRooms: state.quotes.selectedRooms
        }),
        dispatch => ({
            updateItem(payload) {
                dispatch(quotesActions.updateGolfVacationItem(payload));
            },
        })
    )
);

export default enhance(AdditionalAmounts);