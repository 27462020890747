import moment from 'moment';

var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat',];
var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const selectMenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
        },
    },
};

export const formatDate = (date) => {
    let momentDate = date ? moment(date) : moment()
    return days[momentDate.day()] + '-' + momentDate.format('MMM-DD-YYYY');
}

export const formatDateForRange = (dateValue) => {
    let date = new Date(dateValue);
    let dateAsNumber = date.getDate();
    let value = days[date.getDay()] + '-' + months[date.getMonth()] + '-' + (dateAsNumber < 10 ? '0' + dateAsNumber : dateAsNumber) + '-' + date.getFullYear();
    return value;
}

export const dateForBackend = (date) => {
    return moment(date).startOf('day').format('YYYY-MM-DD');
}

export const debounce = (func, timeout = 1000) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

export const capitalizeText = (text) => {
    var splitStr = text.split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }

    return splitStr.join(' ');
}

export const formatAndCapitalizeText = (text) => {
    var splitStr = text.split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1).toLowerCase();
    }

    return splitStr.join(' ');
}


export const sortCoursesByDate = (courses) => {
    return courses.sort((a, b) => {
        return moment(a.date_time).hour(a.hour).utc().minute(a.minute).valueOf() -
            moment(b.date_time).hour(b.hour).utc().minute(b.minute).valueOf();
    });
}

export const getNights = (dateOfArrival = null, dateOfDeparture = null) => {
    if (dateOfArrival === null || dateOfDeparture === null) {
        return 0;
    }

    return diffDays(dateOfArrival, dateOfDeparture);
}

export const getRounds = (courses) => {
    return courses.reduce((acc, course) => {
        if (course.course.value !== 0) { //ignore the No Golf Scheduled
            acc += 1;
        }
        return acc;
    }, 0)
}

export const calculateTotals = (gvi) => {
    const { selectedRooms, selectedCourses, additionalAmounts } = gvi;

    let totals = {
        lodging: 0,
        lodging_markup: 0,
        courses: 0,
        courses_markup: 0,
        total: 0,
        additional_amount: 0,
        additional_amount_markup: 0
    }

    selectedRooms.forEach(room => {
        totals.lodging += parseFloat(room.cost || 0);
        totals.lodging_markup += roundLikePHP((parseFloat(room.cost || 0)) * (parseFloat(room.markup || 0) / 100));
    })

    additionalAmounts.forEach(amount => {
        totals.additional_amount += parseFloat(amount.amount || 0);
        totals.additional_amount_markup += roundLikePHP((parseFloat(amount.amount || 0)) * (parseFloat(amount.markup || 0) / 100));
    })

    selectedCourses.forEach(course => {
        totals.courses += parseFloat(course.price || 0);
        totals.courses_markup += roundLikePHP((parseFloat(course.price || 0)) * (parseFloat(course.markup || 0) / 100));
    })

    totals.total = totals.lodging + totals.lodging_markup + totals.courses_markup + totals.courses + totals.additional_amount_markup + totals.additional_amount;

    totals.lodging = totals.lodging.toFixed(2);
    totals.lodging_markup = totals.lodging_markup.toFixed(2);
    totals.courses = totals.courses.toFixed(2);
    totals.courses_markup = totals.courses_markup.toFixed(2);
    totals.total_rounded = totals.total.toFixed(2);
    totals.total = totals.total.toFixed(2);

    return totals;
}

export const diffDays = (from, to) => {
    let formattedFrom = moment(new Date(Date.parse(from)), "YYYY-MM-DD").startOf('day');
    let formattedTo = moment(new Date(Date.parse(to)), "YYYY-MM-DD").startOf('day');
    return formattedTo.diff(formattedFrom, 'days');
}

export const roundLikePHP = (num, dec = 0) => {
    var num_sign = num >= 0 ? 1 : -1;
    return parseFloat((Math.round((num * Math.pow(10, dec)) + (num_sign * 0.0001)) / Math.pow(10, dec)).toFixed(dec));
}

