import React, { Fragment, useMemo, useEffect, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Tooltip, IconButton, Typography, FormGroup, FormControlLabel, Switch } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { ConfirmDialog } from '../../components/dialog';
import { useClasses } from '../../config';
import styles from './styles';
import { PaymentsHistoryTooltip } from '../../components/popup';

const PaidQuotes = (props) => {

    const [showPastResults, setShowPastResults] = useState(false);
    const [showSendVoucherDialog, setShowSendVoucherDialog] = useState(false);
    const [selectedIdForVoucher, setSelectedIdForVoucher] = useState(0);

    const navigate = useNavigate();
    const classes = useClasses(styles);
    const { paidQuotesList, sendPreTripFollowupEmail, sendVoucher, sendAfterTripFollowupEmail, sendThankYouEmail } = props;

    let data = paidQuotesList.filter(item => showPastResults ? item.days_left < 0 : item.days_left >= 0);

    useEffect(() => {
        props.getPaidQuotes();
    }, []);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'golf_vacation_item_id',
                header: 'Golf Vacation Item Id',
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
            {
                accessorKey: 'title',
                header: 'Title',
                size: 250,
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
            {
                accessorKey: 'date_of_arrival',
                header: 'Date of arrival',
                size: 100,
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
            {
                accessorKey: 'days_left',
                header: 'Days left',
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
            {
                accessorKey: 'nr_golfers',
                header: 'No. of golfers',
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
            {
                accessorKey: 'amount_paid',
                header: 'Paid Amount',
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
            {
                accessorKey: 'golf_vacation_total',
                header: 'Total',
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
            {
                accessorKey: 'voucher_email_sent',
                header: 'Voucher sent',
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
        ],
        [],
    );

    const showConfirmationDialogForVoucherEmail = (id) => {
        setSelectedIdForVoucher(id);
        setShowSendVoucherDialog(true);
    }

    const sendVoucherEmail = () => {
        sendVoucher({ id: selectedIdForVoucher });
        setShowSendVoucherDialog(false);
    }

    return (
        <Fragment>
            <div className={`paidQuotesSection mainSection ${classes.paidQuotesTable}`}>
                <MaterialReactTable
                    columns={columns}
                    data={data}
                    enableSorting={false}
                    enableColumnActions={false}
                    defaultColumn={{ maxSize: 400, size: 60 }}
                    muiPaginationProps={{
                        rowsPerPageOptions: [10, 15, 100]
                    }}
                    enableRowActions
                    positionActionsColumn='last'
                    renderRowActions={({ row, table }) => (
                        <div className={`actionButtons ${classes.actionsColumn}`}>
                            <Tooltip title="Edit">
                                <IconButton
                                    onClick={() => { navigate(`/golf-vacation/${row.original.quote_id}`) }}
                                >
                                    <EditIcon sx={{color: 'black'}}/>
                                </IconButton>
                            </Tooltip>
                            <PaymentsHistoryTooltip id={row.original.quote_id}/>
                            {
                                showPastResults ?
                                    <Fragment>
                                        {
                                            row.original.after_follow_up === 0 &&
                                            <Tooltip title="Send After Trip Followup Email">
                                                <IconButton
                                                    onClick={() => sendAfterTripFollowupEmail({ id: row.original.quote_id })}
                                                >
                                                    <ContactMailIcon color='primary' />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                        {
                                            row.original.thank_you_email === 0 &&
                                            <Tooltip title="Send Thank You Email">
                                                <IconButton
                                                    onClick={() => sendThankYouEmail({ id: row.original.quote_id })}
                                                >
                                                    <EmailIcon color='secondary' />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </Fragment>
                                    :
                                    <Fragment>
                                        {
                                            row.original.has_course_with_voucher &&
                                            <Tooltip title="Send Voucher">
                                                <IconButton
                                                    onClick={() => showConfirmationDialogForVoucherEmail(row.original.golf_vacation_item_id)}
                                                >
                                                    <EmailIcon color='primary' />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                        <Tooltip title="Send Pre-Trip Follow up Email">
                                            <IconButton
                                                onClick={() => { sendPreTripFollowupEmail({ id: row.original.quote_id }) }}
                                            >
                                                <ContactMailIcon color='primary' />
                                            </IconButton>
                                        </Tooltip>
                                    </Fragment>
                            }

                        </div>
                    )}
                    renderTopToolbarCustomActions={() =>
                        <div className={classes.tableTitle}>
                            <Typography variant='h6'>Paid Quotes</Typography>
                        </div>
                    }
                    renderToolbarInternalActions={({ table }) =>
                        <FormGroup>
                            <FormControlLabel label="Past" control={
                                <Switch
                                    checked={showPastResults}
                                    onChange={(event) => setShowPastResults(event.target.checked)}
                                />
                            } />
                        </FormGroup>
                    }
                />
            </div>
            <ConfirmDialog
                open={showSendVoucherDialog}
                text='Are you sure you want to send the voucher?'
                okText='Yes'
                okAction={sendVoucherEmail}
                cancelText='No'
                cancelAction={() => { setShowSendVoucherDialog(false) }}
            />
        </Fragment>
    );
}

export default PaidQuotes;