import React, { Fragment, useState } from 'react';
import { Button, Box, Chip, Checkbox, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Radio, RadioGroup, Select, TextField, Tooltip } from '@mui/material';
import { formatDateForRange } from '../../config/utils';
import clsx from 'clsx';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import RefreshIcon from '@mui/icons-material/Refresh';


const filterByTypeValues = [
    'Course',
    'Lodging'
];


const QuotesTableFilters = (props) => {

    const [showFilters, setShowFilters] = useState(false);
    const {
        classes, globalFilter, handleGlobalFilterChange, typeFilter, typeFilterMenuOpen, setTypeFilterMenuOpen, handleTypeFilterChange,
        dateRangeFilter, handleDateRangeChange, publishedFilter, handlePublishedFilterChange,
        confirmedFilter, handleConfirmedFilterChange, resetFilters
    } = props;

    const displayRangeValue = () => {
        if (dateRangeFilter.length === 2) {
            if (dateRangeFilter[0] === undefined && dateRangeFilter[1] === undefined) {
                return 'Choose dates';
            }
            return `${formatDateForRange(dateRangeFilter[0])}  →  ${formatDateForRange(dateRangeFilter[1])}`;
        } else {
            return 'Choose dates';
        }
    }

    return (
        <div className={classes.internalActions}>
            <div className={classes.searchContainer}>
                <TextField
                    value={globalFilter}
                    onChange={(event) => handleGlobalFilterChange(event.target.value)}
                    className={clsx('quotesSearchFilter', { [classes.searchInputWithoutFilters]: !showFilters }, { [classes.searchInputWithFilters]: showFilters })}
                    size='small'
                    placeholder='Search'
                    InputProps={{
                        startAdornment: <InputAdornment className={classes.searchIcon} position='start'>
                            {globalFilter ? <IconButton onClick={() => handleGlobalFilterChange('')}>
                                <SearchOffIcon sx={{ color: 'black' }} />
                            </IconButton>
                                : <SearchIcon sx={{ color: 'black' }} />}

                        </InputAdornment>,
                    }}
                />
                <Button
                    variant='contained'
                    className={`${classes.filterToggleButton} filterToggleButton`}
                    onClick={() => { setShowFilters(prev => !prev) }}
                >
                    {showFilters
                        ? <FilterListOffIcon />
                        : <FilterListIcon />
                    }
                </Button>
                <Button
                    variant='contained'
                    className={`${classes.filterToggleButton} filterToggleButton`}
                    onClick={resetFilters}
                >
                    <RefreshIcon />
                </Button>
            </div>
            {
                showFilters &&
                <Fragment>
                    <div className={classes.dateRangeContainer}>
                        <FormControl size='small' className={classes.filterInput}>
                            <InputLabel id='filterby-select'>Filter by</InputLabel>
                            <Select
                                labelId='filterby-select'
                                multiple
                                value={typeFilter}
                                onChange={handleTypeFilterChange}
                                renderValue={(selected) => (
                                    <Box className={classes.filterTypeBox} >
                                        {selected.map((value) => (
                                            <Chip className={classes.filterTypeChip} key={value} label={value} />
                                        ))}
                                    </Box>
                                )}
                                input={<OutlinedInput label='Filter by' />}
                                open={typeFilterMenuOpen}
                                onOpen={() => setTypeFilterMenuOpen(true)}
                                onClose={() => setTypeFilterMenuOpen(false)}
                            >
                                {filterByTypeValues.map((value) => (
                                    <MenuItem key={value} value={value}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <DateRangePicker
                            locale={'en-US'}
                            value={dateRangeFilter}
                            onChange={handleDateRangeChange}
                            className={classes.dateRangeInput}
                            calendarClassName={classes.dateRangeCalendar}
                            calendarIcon={displayRangeValue}
                            clearIcon={<ClearIcon className={classes.dateRangeIcon} />}
                        />
                    </div>
                    <div className={`${classes.statusFiltersContainer} statusFiltersSection`}>
                        <FormControl>
                            <RadioGroup
                                row
                                value={publishedFilter}
                                className={classes.statusRadioGroup}
                            >
                                <FormControlLabel
                                    value='published'
                                    control={<Radio onClick={handlePublishedFilterChange} size='small' />}
                                    label='Published'
                                    className='statusFilterLabel'
                                />
                                <FormControlLabel
                                    value='not published'
                                    control={<Radio onClick={handlePublishedFilterChange} size='small' />}
                                    label='Not Published'
                                    className='statusFilterLabel'
                                />
                            </RadioGroup>
                        </FormControl>
                        <FormControl>
                            <RadioGroup
                                row
                                value={confirmedFilter}
                                className={classes.statusRadioGroup}
                            >
                                <FormControlLabel
                                    value='confirmed'
                                    control={<Radio onClick={handleConfirmedFilterChange} size='small' />}
                                    label='Confirmed'
                                    className='statusFilterLabel'
                                />
                                <FormControlLabel
                                    value='not confirmed'
                                    control={<Radio onClick={handleConfirmedFilterChange} size='small' />}
                                    label='Not Confirmed'
                                    className='statusFilterLabel'
                                />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </Fragment>
            }
        </div>
    );
}

export default QuotesTableFilters;