let numberOfGolfersLimit = 121;
let indexGolfersList = [];
for (let index = 0; index < numberOfGolfersLimit; index++) {
    indexGolfersList.push({ value: index, label: `${index}` });
}

export const numberOfGolfersOptions = indexGolfersList;

export const currencyOptions = [
    { value: 'USD', label: 'USD' },
    { value: 'CAD', label: 'CAD' },
    { value: 'GBP', label: 'GBP' },
    { value: 'EUR', label: 'EUR' },
];

export const tbdDaysOptions = [
    { value: 'off', label: 'Off' },
    { value: 30, label: '30' },
    { value: 60, label: '60' },
    { value: 90, label: '90' },
    { value: 120, label: '120' },
    { value: 150, label: '150' },
    { value: 180, label: '180' },
    { value: 210, label: '210' },
    { value: 240, label: '240' },
    { value: 270, label: '270' },
    { value: 300, label: '300' },
    { value: 330, label: '330' },
    { value: 360, label: '360' },
    { value: 390, label: '390' },
    { value: 420, label: '420' },
    { value: 450, label: '450' },
    { value: 480, label: '480' },
    { value: 510, label: '510' },
    { value: 540, label: '540' },
    { value: 570, label: '570' },
    { value: 600, label: '600' },
    { value: 630, label: '630' },
    { value: 660, label: '660' },
    { value: 690, label: '690' },
    { value: 720, label: '720' }
];

export const defaultTimeSlots = [
    "07:00 - 08:00",
    "08:00 - 09:00",
    "09:00 - 10:00",
    "10:00 - 11:00", 
    "11:00 - 12:00", 
    "12:00 - 13:00", 
    "13:00 - 14:00", 
    "14:00 - 15:00", 
    "15:00 - 16:00", 
    "16:00 - 17:00", 
    "17:00 - 18:00", 
    "18:00 - 19:00", 
    "19:00 - 20:00",
    "20:00 - 21:00",
    "21:00 - 22:00",
    "22:00 - 23:00"
];

export const hours = [
    {value: 'am', label: 'AM', disabled: true}, { value: '06', label: '6' },{ value: '07', label: '7' },{ value: '08', label: '8' },{ value: '09', label: '9' },{ value: '10', label: '10' },{ value: '11', label: '11' },{value: 'pm', label: 'PM', disabled: true},{ value: '12', label: '12' },{ value: '13', label: '1' },{ value: '14', label: '2' },{ value: '15', label: '3' },{ value: '16', label: '4' },{ value: '17', label: '5' },{ value: '18', label: '6' },{ value: '19', label: '7' },{ value: '20', label: '8' }, { value: '21', label: '9' }
];

export const minutes= [
    { value: '00', label: '00' },{ value: '01', label: '01' },{ value:'02', label: '02' },{ value: '03', label: '03' },{ value: '04', label: '04' },{ value: '05', label: '05' },{ value: '06', label: '06' },{ value: '07', label: '07' },{ value: '08', label: '08' },{ value: '09', label: '09' },{ value: '10', label: '10' },{ value: '11', label: '11' },{ value: '12', label: '12' },{ value: '13', label: '13' },{ value: '14', label: '14' },{ value: '15', label: '15' },{ value: '16', label: '16' },{ value: '17', label: '17' },{ value: '18', label: '18' },{ value: '19', label: '19' },{ value: '20', label: '20' },{ value: '21', label: '21' },{ value: '22', label: '22' },{ value: '23', label: '23' },{ value: '24', label: '24' },{ value: '25', label: '25' },{ value: '26', label: '26' },{ value: '27', label: '27' },{ value: '28', label: '28' },{ value: '29', label: '29' },{ value: '30', label: '30' },{ value: '31', label: '31' },{ value: '32', label: '32' },{ value: '33', label: '33' },{ value: '34', label: '34' },{ value: '35', label: '35' },{ value: '36', label: '36' },{ value: '37', label: '37' },{ value: '38', label: '38' },{ value: '39', label: '39' },{ value: '40', label: '40' },{ value: '41', label: '41' },{ value: '42', label: '42' },{ value: '43', label: '43' },{ value: '44', label: '44' },{ value: '45', label: '45' },{ value: '46', label: '46' },{ value: '47', label: '47' },{ value: '48', label: '48' },{ value: '49', label: '49' },{ value: '50', label: '50' },{ value: '51', label: '51' },{ value: '52', label: '52' },{ value: '53', label: '53' },{ value: '54', label: '54' },{ value: '55', label: '55' },{ value: '56', label: '56' },{ value: '57', label: '57' },{ value: '58', label: '58' },{ value: '59', label: '59' }
];

export const INITIAL_GOLF_VACATION_ITEM = {
    additional_amounts: [],
    check_in_location: '',
    confirmation_id: '',
    content_box: 'Includes taxes, advanced reserved tee times and shared golf cart.',
    content_box_lodging: 'Includes all taxes and resort service fees.',
    currency: {'value': 'USD','label': 'USD'},
    date_of_arrival: '',
    date_of_arrival_and_departure: [],
    date_of_departure: '',
    deposit_per: {'label': 'per_golfer','value': 'per_golfer'},
    description: '',
    due_days: 30,
    first_name: '',
    golf_package: {id: null},
    hasCourseWithVoucher: false,
    id: 0,
    last_name: '',
    markup: 0,
    nights: 0,
    note: '',
    number_golfers: {'value': 0,'label': '0'},
    number_nights: {'value': 0,'label': '0'},
    number_non_golfers: {'value': 0,'label': '0'},
    number_rounds: {'value': 0,'label': '0'},
    occupancy: 'single',
    other_occupancy: '',
    paidInFull: false,
    pdf_description: '',
    price: 0,
    price_as_deposit: false,
    price_disclaimer: 'on', // check what is this 
    published: 0,
    reservation_name: '',
    resort_title: '',
    rounds: 0,
    show_tpp: false,
    status: 1,
    title: '',
    total_price: 0,
    total_price_per:  {'label': 'per_golfer','value': 'per_golfer'},
    totals: { 'lodging': 0, 'lodging_markup': 0, 'courses': 0, 'courses_markup': 0, 'total': 0, 'additional_amount': 0, 'additional_amount_markup': 0, 'total_rounded': 0},
    unlimited_rounds: false,
    vacation_id: 0,
    voucher_email_sent: 0
}
